'use client'

import { GoogleTagManager as GTM } from '@next/third-parties/google'

const gtmProps = {
  gtmId: process.env.NEXT_PUBLIC_GTM_ID ?? '',
  ...(process.env.NEXT_PUBLIC_GTM_AUTH && { auth: process.env.NEXT_PUBLIC_GTM_AUTH }),
  ...(process.env.NEXT_PUBLIC_GTM_PREVIEW && { preview: process.env.NEXT_PUBLIC_GTM_PREVIEW }),
}

const isTest = process.env.NEXT_PUBLIC_GTM_ID === 'TEST'

const GoogleTagManager = () => {
  if (isTest) {
    return null
  }

  return <GTM {...gtmProps} />
}

export default GoogleTagManager

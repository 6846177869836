import useSWR from 'swr'

import { client } from 'src/api'
import { storage } from 'src/storage'
import type { ActiveCouponCode, Coupon, EnhancedCoupon } from 'src/types/Coupon'

export const getActiveCouponCode = async () => {
  try {
    const { data } = await client.get<ActiveCouponCode>('/api/v4/active_coupon_codes/latest')
    return data?.code
  } catch (error) {
    return null
  }
}

export async function fetchStripeCoupon(code: string): Promise<EnhancedCoupon> {
  const { data } = await client.get<Coupon>(`/api/v3/coupon/${code}?payment_provider=stripe`)

  return {
    ...data,
    isValid: data.valid,
    forAnnualPlan: data.metadata?.forAnnualPlan === 'true',
    forQuarterlyPlan: data.metadata?.forQuarterlyPlan === 'true',
    canSelectPlan: data.metadata?.canSelectPlan === 'true',
    extendedTrialDays: data.metadata?.extendedTrialDays,
    showTestimonials: data.metadata?.hideTestimonials !== 'true',
  }
}

export const useStripeCoupon = (code: string | null | undefined) => {
  const { data: couponData, isValidating: isLoadingCoupon } = useSWR('getCoupon', () =>
    fetchStripeCoupon(code || getStoredCouponCode() || ''),
  )

  return {
    coupon: couponData,
    isLoading: isLoadingCoupon,
  }
}

export const setStoredStripeCoupon = (code: string) =>
  storage.session.setItem(obe.storage_keys.promotion_code, btoa(code))

export const useStoredStripeCoupon = () => useStripeCoupon(getStoredCouponCode())

export const removeStoredCouponCode = () =>
  storage.session.removeItem(obe.storage_keys.promotion_code)

export const getStoredCouponCode = () => {
  try {
    const code = storage.session.getItem(obe.storage_keys.promotion_code) as string | null
    return code ? atob(code).toUpperCase() : null
  } catch (error) {
    removeStoredCouponCode()
    return null
  }
}

export const removeStoredGuestPassCode = () => {
  if (getStoredCouponCode() === obe.promo_codes.guest_pass) {
    removeStoredCouponCode()
    storage.session.removeItem('userId')
    storage.session.removeItem('referralName')
  }
}
